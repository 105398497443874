import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const OldMemoriesPage = () => {
  return (
    <Layout title="Old Memories">
        <h2>Scenario</h2>
        <blockquote>[...] but after the end I am left with memories of my life, memories that age as I age, memories written in pencil which are continually erased and redrawn [...] and I better remember the voice of the mountain, the voice of the dogwood tree, than I do the voice of any lover...</blockquote>
        <center><Image src="oldmemories.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default OldMemoriesPage